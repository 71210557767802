.App{
    color: var(--text-primary);
    /* background-color: var(--background); */
    background-image: 
        radial-gradient(#0000, rgba(0, 0, 0, 0.75) 100%),
        repeating-conic-gradient(from 0deg, var(--background1) 0deg 90deg, var(--background2) 90deg 180deg);
    background-position: left 30px top 30px;    
    background-size: 100%,100px 100px;
    font-size: large;
    font-weight: bold;
    /* height: calc(100vh); */
    transition: all .5s;
    
}

button{
    color: var(--text-primary);
    background-color: var(--background);
    border: 2px var(--text-primary) solid;
    float: right;
    transition: all .5s;
}

.app_content {
    display: inline-flex;
    margin-top: 40px;
    
    width: 100%;
}