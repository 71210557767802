.cube {
    width: 200px;
    height: 200px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-100px);
    transition: transform 3s;
}

.scene {
    width: 200px;
    height: 200px;
    perspective: 400px;
    margin :20px;
    float: left;
}

.cube.show-front {
    transform: translateZ(-100px) rotateY(0deg);
}

.cube.show-right {
    transform: translateZ(-100px) rotateY(-90deg);
}

.cube.show-back {
    transform: translateZ(-100px) rotateY(-180deg);
}

.cube.show-left {
    transform: translateZ(-100px) rotateY(90deg);
}

.cube.show-top {
    transform: translateZ(-100px) rotateX(-90deg);
}

.cube.show-bottom {
    transform: translateZ(-100px) rotateX(90deg);
}

.cube_face {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 2px solid black;
    line-height: 200px;
    color: var(--text-primary);
    font-size: 40px;
    text-align: center;
}

.cube>#front {
    background: var(--qube1);
    transform: rotateY(0deg) translateZ(100px);
}

.cube>#right {
    background: var(--qube2);
    transform: rotateY(90deg) translateZ(100px);
}

.cube>#back {
    background: var(--qube3);
    transform: rotateY(180deg) translateZ(100px);
}

.cube>#left {
    background: var(--qube4);
    transform: rotateY(-90deg) translateZ(100px);
}

.cube>#top {
    background: var(--qube5);
    transform: rotateX(90deg) translateZ(100px);
}

.cube>#bottom {
    background: var(--qube6);
    transform: rotateX(-90deg) translateZ(100px);
}

:root {
    --qube1: rgba(0, 155, 72, 0.702);
    --qube2: rgba(185, 0, 0, 0.7);
    --qube3: rgba(0, 69, 173, 0.7);
    --qube4: rgba(255, 89, 0, 0.7);
    --qube5: rgba(255, 255, 255, 0.7);
    --qube6: rgba(255, 213, 0, 0.7);
}

[data-theme='dark'] {
    --qube1: rgba(250, 207, 167, 0.702);
    --qube2: rgba(152, 211, 217, 0.7);
    --qube3: rgba(66, 164, 189, 0.7);
    --qube4: rgba(67, 67, 91, 0.7);
    --qube5: rgba(160, 77, 85, 0.7);
    --qube6: rgba(248, 107, 116, 0.7);
}