.switch{
    position: relative;
    display: inline-block;
    height: 25px;
    width: 45px;
}

.switch input
{
    opacity: 0;
    width: 0;
    height: 0;
}
.slider
{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before{
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider:before{
    transform: translateX(18px);
    background-color: #1f72b6;
}

input:checked + .slider{
    background-color: #133d5f;
}