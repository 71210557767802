.page_content
{
    padding: 10px;
    width: calc(100% - 310px);
    background-color: rgba(var(--background1-raw),0.65);
    border-radius: 10px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    left: 250px;
    z-index: 1;
}

.work{
    color: var(--accent);
}