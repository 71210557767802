.nav{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: rgba(var(--background1-raw), 0.7);
    z-index: 100;
}

.nav div
{
    display: inline-block;
}